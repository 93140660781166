import React from "react"
import type { SignupSurveyPayload } from "@framerjs/framer-events"
import { Button, Label, Select, Text, Stack } from "@framerjs/fraction"
import Intro from "../components/intro"

enum AccountType {
  Business = "business",
  Personal = "personal",
  Client = "client",
  Other = "other",
}

enum ClientAccountType {
  Freelancer = "client-freelance",
  Agency = "client-agency",
  Other = "client-other",
}

enum Role {
  Designer = "designer",
  Leadership = "leadership",
  Marketer = "marketer",
  Other = "other",
  Sales = "sales",
}

enum TeamSize {
  One = "1",
  Two = "2",
  Ten = "10",
  Hundred = "100",
  Fifty = "50",
  FiveHundred = "500",
}

type SurveyState =
  | { accountType: null | AccountType.Personal | AccountType.Other }
  | {
      accountType: AccountType.Business
      role: Role | null
      teamSize: TeamSize | null
    }
  | {
      accountType: AccountType.Client
      clientAccountType: null | ClientAccountType.Freelancer | ClientAccountType.Other
    }
  | {
      accountType: AccountType.Client
      clientAccountType: ClientAccountType.Agency
      role: Role | null
      teamSize: TeamSize | null
    }

enum ActionType {
  SetAccountType = 0,
  SetRole = 1,
  SetTeamSize = 2,
  SetClientAccountType = 3,
}

type Action =
  | { type: ActionType.SetAccountType; accountType: AccountType }
  | { type: ActionType.SetRole; role: Role }
  | { type: ActionType.SetTeamSize; teamSize: TeamSize }
  | { type: ActionType.SetClientAccountType; clientAccountType: ClientAccountType }

function reducer(state: SurveyState, action: Action): SurveyState {
  switch (action.type) {
    case ActionType.SetAccountType:
      switch (action.accountType) {
        case AccountType.Personal:
        case AccountType.Other:
          return { accountType: action.accountType }
        case AccountType.Business:
          return { accountType: AccountType.Business, role: null, teamSize: null }
        case AccountType.Client:
          return { accountType: AccountType.Client, clientAccountType: null }
        default:
          return assertNever(action.accountType)
      }
    case ActionType.SetClientAccountType: {
      if (state.accountType !== AccountType.Client) return state
      switch (action.clientAccountType) {
        case ClientAccountType.Freelancer:
        case ClientAccountType.Other:
          return { accountType: AccountType.Client, clientAccountType: action.clientAccountType }
        case ClientAccountType.Agency:
          return {
            accountType: AccountType.Client,
            clientAccountType: ClientAccountType.Agency,
            role: null,
            teamSize: null,
          }
        default:
          return assertNever(action.clientAccountType)
      }
    }
    case ActionType.SetTeamSize: {
      if (
        state.accountType === AccountType.Business ||
        (state.accountType === AccountType.Client && state.clientAccountType === ClientAccountType.Agency)
      ) {
        return { ...state, teamSize: action.teamSize }
      }
      return state
    }
    case ActionType.SetRole: {
      if (
        state.accountType === AccountType.Business ||
        (state.accountType === AccountType.Client && state.clientAccountType === ClientAccountType.Agency)
      ) {
        return { ...state, role: action.role }
      }
      return state
    }
    default:
      return state
  }
}

interface ISurveyProps {
  onComplete: (payload: SignupSurveyPayload) => void
}
export default function Survey({ onComplete }: ISurveyProps) {
  return (
    <>
      <Intro heading="What will you use Framer for?" icon={null} />
      <SurveyInner onComplete={onComplete} />
    </>
  )
}

function SurveyInner({ onComplete }: ISurveyProps) {
  const [state, dispatch] = React.useReducer(reducer, { accountType: null })
  const payload = React.useMemo(() => getEventPayload(state), [state])

  const dropdowns: React.ReactNode[] = [
    <Dropdown
      key="account-type"
      value={state.accountType}
      onChange={(accountType) =>
        isAccountType(accountType) && dispatch({ type: ActionType.SetAccountType, accountType })
      }
    >
      {state.accountType === null && <option>Select an option</option>}
      <option value={AccountType.Personal}>Personal (e.g. portolio site)</option>
      <option value={AccountType.Business}>Business (e.g. company site)</option>
      <option value={AccountType.Client}>Client work</option>
      <option value={AccountType.Other}>Other</option>
    </Dropdown>,
  ]

  if (state.accountType === AccountType.Client) {
    dropdowns.push(
      <Dropdown
        key="client-type"
        value={state.clientAccountType}
        label="What describes you the best?"
        onChange={(clientAccountType) =>
          isClientAccountType(clientAccountType) &&
          dispatch({ type: ActionType.SetClientAccountType, clientAccountType })
        }
      >
        {state.clientAccountType === null && <option>Select an option</option>}
        <option value={ClientAccountType.Freelancer}>Freelancer</option>
        <option value={ClientAccountType.Agency}>Part of an agency</option>
        <option value={ClientAccountType.Other}>Other</option>
      </Dropdown>
    )
  }

  if (
    state.accountType === AccountType.Business ||
    (state.accountType === AccountType.Client && state.clientAccountType === ClientAccountType.Agency)
  ) {
    dropdowns.push(
      <Dropdown
        key="team-size"
        value={state.teamSize}
        label="What’s your company size?"
        onChange={(teamSize) => isTeamSize(teamSize) && dispatch({ type: ActionType.SetTeamSize, teamSize })}
      >
        {state.teamSize === null && <option>Select an option</option>}
        <option value={TeamSize.One}>I work alone</option>
        <option value={TeamSize.Two}>2–10 employees</option>
        <option value={TeamSize.Ten}>10–50 employees</option>
        <option value={TeamSize.Fifty}>50–100 employees</option>
        <option value={TeamSize.Hundred}>100–500 employees</option>
        <option value={TeamSize.FiveHundred}>500+ employees</option>
      </Dropdown>
    )
  }

  if (
    (state.accountType === AccountType.Business ||
      (state.accountType === AccountType.Client && state.clientAccountType === ClientAccountType.Agency)) &&
    state.teamSize
  ) {
    dropdowns.push(
      <Dropdown
        key="role"
        value={state.role}
        label="How would you describe your role?"
        onChange={(role) => isRole(role) && dispatch({ type: ActionType.SetRole, role })}
      >
        {state.role === null && <option>Select an option</option>}
        <option value={Role.Designer}>Designer</option>
        <option value={Role.Marketer}>Marketer</option>
        <option value={Role.Leadership}>Leadership</option>
        <option value={Role.Sales}>Sales</option>
        <option value={Role.Other}>Other</option>
      </Dropdown>
    )
  }

  return (
    <Stack direction="column" gap={30}>
      {dropdowns}
      <Button type="button" variant="primary" fluid disabled={!payload} onClick={() => payload && onComplete(payload)}>
        Continue
      </Button>
    </Stack>
  )
}

function Dropdown(props: {
  value: string | null
  onChange: (value: string) => void
  children: React.ReactNode
  label?: React.ReactNode
}) {
  const select = (
    <Select
      value={props.value ?? ""}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => props.onChange(e.currentTarget.value)}
      background="rgba(255,255,255,0.1)"
      style={{
        color: props.value ? "#fff" : "#bababa",
        backgroundImage:
          "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgOCA2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTcuNTMwMzMgMC45Njk2N0M3LjgyMzIyIDEuMjYyNTYgNy44MjMyMiAxLjczNzQ0IDcuNTMwMzMgMi4wMzAzM0w0IDUuNTYwNjZMMC40Njk2NyAyLjAzMDMzQzAuMTc2Nzc3IDEuNzM3NDQgMC4xNzY3NzcgMS4yNjI1NiAwLjQ2OTY3IDAuOTY5NjdDMC43NjI1NjMgMC42NzY3NzcgMS4yMzc0NCAwLjY3Njc3NyAxLjUzMDMzIDAuOTY5NjdMNCAzLjQzOTM0TDYuNDY5NjcgMC45Njk2N0M2Ljc2MjU2IDAuNjc2Nzc3IDcuMjM3NDQgMC42NzY3NzcgNy41MzAzMyAwLjk2OTY3WiIgZmlsbD0iI0JBQkFCQSIvPgo8L3N2Zz4K')",
      }}
    >
      {props.children}
    </Select>
  )

  if (!props.label) return select

  return (
    <Label as="label" style={{ margin: 0 }}>
      <Stack direction="column" gap={10}>
        <Text size="medium" color="#fff">
          {props.label}
        </Text>
        {select}
      </Stack>
    </Label>
  )
}

/**
 * @param state Survey state for which to generate event payload.
 * @returns Event payload or null if state is invalid
 */
function getEventPayload(state: SurveyState): SignupSurveyPayload | null {
  switch (state.accountType) {
    case AccountType.Personal:
    case AccountType.Other:
      return { accountType: state.accountType }
    case AccountType.Business:
      return state.role && state.teamSize
        ? {
            accountType: state.accountType,
            role: state.role,
            teamSize: state.teamSize,
          }
        : null
    case AccountType.Client:
      switch (state.clientAccountType) {
        case ClientAccountType.Freelancer:
        case ClientAccountType.Other:
          return { accountType: state.clientAccountType }
        case ClientAccountType.Agency:
          return state.role && state.teamSize
            ? {
                accountType: state.clientAccountType,
                role: state.role,
                teamSize: state.teamSize,
              }
            : null
        default:
          return null
      }
    default:
      return null
  }
}

function isTeamSize(value: string): value is TeamSize {
  return (Object.values(TeamSize) as string[]).includes(value)
}

function isRole(value: string): value is Role {
  return (Object.values(Role) as string[]).includes(value)
}

function isClientAccountType(value: string): value is ClientAccountType {
  return (Object.values(ClientAccountType) as string[]).includes(value)
}

function isAccountType(value: string): value is AccountType {
  return (Object.values(AccountType) as string[]).includes(value)
}

function assertNever(x: never): never {
  throw new Error(`Unexpected value: ${x}`)
}
