export enum Routes {
  // The home route will either show the sign-in form, or redirect to /continue/
  // if the user is already logged in.
  home = "/",
  // The signIn route will always show the sign-in form, even if the user is
  // already logged in. This exist so that we can force a user to log in again
  // if needed, e.g., because they don't have the session.embed cookie required
  // to view private, embedded prototypes.
  signIn = "/sign-in/",
  createAccount = "/sign-up/",
  completeSignUp = "/complete-sign-up/",
  invite = "/invite/",
  activate = "/activate/",
  continueAs = "/continue/",
  team = "/team/",
  survey = "/survey/",

  // Framer does not work on mobile, so we show a different UI for the users who sign up via their phones
  mobile = "/mobile/",
}
