import * as FramerEvents from "@framerjs/framer-events"
import { v4 as uuidv4 } from "uuid"
import { EVENTS_URL } from "../env"
import { getCookie } from "./getCookie"

function trackGTMEvent(
  action: string,
  category: string,
  data: {
    firstName: string
    lastName: string
    email: string
  }
) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "eventTracking",
      trackingEventCategory: category,
      trackingEventAction: action,
      trackingEventLabel: "",
      trackingEventValue: "",
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
    })
  }
}

// This is the main 'conversion' event, which triggers several tags in GTM
export const trackSignupCompletion = (data: { firstName: string; lastName: string; email: string }) =>
  trackGTMEvent("Web", "CompleteRegistration", data)

interface TrackEvent {
  data: {
    type: "track"
    event: string
    uuid: string
    context: {
      userId?: string | null
      visitorId?: string | null
    }
    // + event-specific data
  }
  source: string
  timestamp: number
}

class FramerTracker {
  private userId?: string | null
  private visitorId = getCookie("visitor_id")

  /**
   * Until this.userId becomes defined (either with a userId string, or null
   * if we determine there's no logged-in user), we'll hold the tracking events
   * here, to be flushed once this.userId becomes defined.
   *
   * This is because checking the logged-in status is an async operation during
   * which events might already start coming — but we don't want to send them to
   * the backend until we can properly attribute them.
   */
  private pendingEvents: TrackEvent[] = []

  constructor() {
    FramerEvents.addEventListener((payload, meta) => {
      if (!meta?.track) return

      const { event: eventName, ...data } = payload
      if (!eventName) return

      const event: TrackEvent = {
        data: {
          ...data,
          type: "track",
          event: eventName,
          uuid: uuidv4(),
          context: {
            userId: this.userId,
            visitorId: this.visitorId,
          },
        },
        source: "framer-login",
        timestamp: Date.now(),
      }
      this.track(event)
    })
  }

  /**
   * Call this once determined whether the user is logged in (with the userId
   * string) or logged out (with null). You can also call this later if the user
   * logs in or out.
   */
  setUserId(userId: string | null) {
    this.userId = userId

    if (this.pendingEvents.length > 0) {
      for (const event of this.pendingEvents) {
        event.data.context.userId = userId
      }
      this.send(this.pendingEvents)
      this.pendingEvents = []
    }
  }

  private track(event: TrackEvent) {
    if (this.userId !== undefined) {
      this.send([event])
    } else {
      this.pendingEvents.push(event)
    }
  }

  private send(events: TrackEvent[]) {
    fetch(`${EVENTS_URL}/track`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(events),
    }).catch((error) => console.warn("Error sending events", error))
  }
}

export const tracker = new FramerTracker()
