import { Button, Checkbox, colors, Stack } from "@framerjs/fraction"
import React from "react"
import styled from "styled-components"
import Avatar from "../../components/avatar"
import Intro from "../../components/intro"
import { IRecommendedTeam, joinTeams } from "../../util/api"
import { extractInitials } from "../../util/emoji"
import { ButtonPlain } from "../../components/button-plain"

const TeamRowContainer = styled.li`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.borderHighlight};
  &:last-child {
    border-bottom: none;
  }
`
const TeamRowLabel = styled.label`
  min-width: 0;
  flex: 1;
  display: flex;
  align-items: center;
`
const TeamRowName = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 15px;
  font-size: 14px;
  color: #fff;
`

const StyledStack = styled(Stack)`
  width: 300px;
  margin: auto;
`

interface ITeamRowProps {
  team: IRecommendedTeam
  checked: boolean
  onChange: (teamId: string, checked: boolean) => void
}

function TeamRow({ team, checked, onChange }: ITeamRowProps) {
  const checkboxId = `join-team-${team.id}`
  const onCheckboxChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(team.id, e.target.checked)
    },
    [onChange, team.id]
  )
  const teamAvatarInitials = extractInitials(team.displayName)
  return (
    <TeamRowContainer>
      <TeamRowLabel htmlFor={checkboxId}>
        {/* FIXME: Team names starting with emoji */}
        <Avatar size={30} src={team.avatar || undefined} text={teamAvatarInitials} />
        <TeamRowName>{team.displayName}</TeamRowName>
      </TeamRowLabel>
      <Checkbox size="small" name={team.id} id={checkboxId} checked={checked} onChange={onCheckboxChange} />
    </TeamRowContainer>
  )
}

const TeamsList = styled.ul`
  margin-top: 10px;
  max-height: 330px;
  overflow-y: scroll;
  width: 110%;
  padding-right: 10%;
`

interface IJoinTeamProps {
  teams: IRecommendedTeam[]
  onJoinedTeams: () => void
  onCreateNewTeam: () => void
  onError: (error: Error) => void
}

export default function JoinTeam({ teams, onCreateNewTeam, onJoinedTeams, onError }: IJoinTeamProps) {
  const [selectedTeamsIds, setSelectedTeamsIds] = React.useState<Set<string>>(
    // All teams are selected by default.
    () => new Set(teams.map((team) => team.id))
  )

  const onSelectionChange = React.useCallback((teamId: string, checked: boolean) => {
    setSelectedTeamsIds((prev) => {
      const newSet = new Set(prev)
      if (checked) {
        newSet.add(teamId)
      } else {
        newSet.delete(teamId)
      }
      return newSet
    })
  }, [])

  const [isJoiningTeams, setIsJoiningTeams] = React.useState(false)

  const onJoinTeams = React.useCallback(() => {
    setIsJoiningTeams(true)
    joinTeams({ teamIds: Array.from(selectedTeamsIds) })
      .then(() => {
        onJoinedTeams()
      })
      .catch((err) => {
        setIsJoiningTeams(false)
        onError(err)
      })
  }, [selectedTeamsIds, onJoinedTeams, onError])

  return (
    <>
      <Intro
        icon={null}
        heading="Join a workspace"
        text={
          <>
            Your organization is already on Framer.
            <br />
            Join any of the existing workspaces.
          </>
        }
      />
      <StyledStack direction="column" gap={30}>
        <TeamsList>
          {teams.map((team) => (
            <TeamRow key={team.id} team={team} checked={selectedTeamsIds.has(team.id)} onChange={onSelectionChange} />
          ))}
        </TeamsList>
        <Stack direction="column" gap={15}>
          <Button fluid onClick={onJoinTeams} variant="primary" loading={isJoiningTeams}>
            Join workspaces
          </Button>
          <ButtonPlain fluid onClick={onCreateNewTeam} variant="primary">
            Create a new workspace
          </ButtonPlain>
        </Stack>
      </StyledStack>
    </>
  )
}
